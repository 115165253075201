<template>
  <div class="box">
    <!-- swiper组件 -->
    <ul class="left_navgation">
      <li
        @click="toNavgationNext(index)"
        v-for="(item, index) in rightNav"
        :key="index"
        @mouseenter="enterRightNav(index)"
        @mouseleave="leaveRightNav(index)"
        :class="index === currentIndex ? 'main_right_nav_active' : ''"
      >
        <transition name="rightNav_txt_fade">
          <span v-text="item.title" v-if="item.isShow"></span>
        </transition>
      </li>
    </ul>

    <swiper
      :slides-per-view="1"
      :space-between="50"
      @swiper="onSwiper"
      :speed="1000"
      direction="vertical"
      class="carousel_style"
      :uniqueNavElements="false"
      :mousewheel="true"
      :forceToAxis="true"
      :slideToClickedSlide="true"
      @slideChangeTransitionEnd="slidingSlide"
      :allowTouchMove="false"
      :preventLinksPropagation="false"
      :observer="true"
      :observeParents="true"
      id="demo2"
    >
      <!-- 第一个轮播 -->
      <swiper-slide>
        <!-- element 走马灯 -->
        <el-carousel
          :interval="3000"
          arrow="always"
          class="carousel_box"
          trigger="click"
          v-if="firstCarouselData"
        >
          <el-carousel-item
            v-for="(item, index) in firstCarouselData"
            :key="index"
            @click="jumpWebPage(index)"
            style="height: 100%"
          >
            <img
              :src="`/api/profile/product/${item.backgroundRotaryBroadcasting}`"
              alt=""
              class="first_carousel_img"
            />
          </el-carousel-item>
        </el-carousel>
        <div>
          <div class="bottom_down">
            <img
              @click="toNext"
              src="../../assets/indexImg/main_btm_btn1.png"
            />
            <img
              @click="toNext"
              src="../../assets/indexImg/main_btm_btn2.png"
              alt=""
            />
          </div>
        </div>
      </swiper-slide>

      <!-- 轮播页2 -->
      <swiper-slide style="color: white" class="second_swiper">
        <!-- 上图背景 -->
        <div class="top_img_bgn">
          <img src="../../assets/indexImg/two_top_img.png" alt="" />
        </div>
        <swiper
          :slides-per-view="4"
          :space-between="30"
          class="second_carousel"
          effect="coverflow"
          :coverflowEffect="{
            rotate: 10,
            stretch: 5,
            depth: 60,
            modifier: 2,
          }"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
          :autoplay="true"
        >

          <swiper-slide
            v-for="(item, index) in secondCarouselData"
            :key="index"
          >
            <div class="second_carousel_box">
              <img
                :src="`/api/profile/product/${item.productRotation}`"
                alt=""
                @click="toProductDetail(item.id)"
              />
            </div>
          </swiper-slide>
          <div class="second_middle_img"></div>
        </swiper>
        <img
          src="../../assets/indexImg/four_more.png"
          alt=""
          class="second_bottom_img"
          @click="goProductPage"
        />
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
        <div class="bottom_down">
          <img @click="toNext" src="../../assets/indexImg/main_btm_btn1.png" />
          <img
            @click="toNext"
            src="../../assets/indexImg/main_btm_btn2.png"
            alt=""
          />
        </div>
      </swiper-slide>
      <!-- 轮播页3 -->
      <swiper-slide
        style="background-color: rgb(34, 34, 34)"
        class="three_swiper"
      >
        <div v-if="isShow">
          <!-- 左图片 -->
          <div class="three_leftbox">
            <img
              src="../../assets/indexImg/three_human.jpg"
              alt=""
              class="three_leftimg"
            />
          </div>
          <!-- 右图片 -->
          <div class="three_rightbox">
            <transition name="top_change" mode="out-in" appear>
              <div>
                <img
                  src="/api/profile/product/2019/03/28/77412d6f46331e42353d97cb32b04fac.jpg"
                  alt=""
                  @click="goNewsDetail"
                />
              </div>
            </transition>
            <transition
              name="change"
              mode="out-in"
              appear
              v-if="isThreeBottomImg"
            >
              <div>
                <img
                  src="../../assets/indexImg/three_more.jpg"
                  alt=""
                  @click="toNewsPage"
                />
              </div>
            </transition>
          </div>

          <!-- 下方图片 -->
          <transition name="change" mode="out-in" appear>
            <div class="three_bottombox" v-if="isThreeBottomImg">
              <img src="../../assets/indexImg/three_news.png" alt="" />
            </div>
          </transition>
        </div>
        <div class="bottom_down">
          <img @click="toNext" src="../../assets/indexImg/main_btm_btn1.png" />
          <img
            @click="toNext"
            src="../../assets/indexImg/main_btm_btn2.png"
            alt=""
          />
        </div>
      </swiper-slide>
      <!-- 轮播页4 -->
      <swiper-slide>
        <div class="four_topbox">
          <div class="logo_box">
            <transition name="four_top_logo">
              <img
                src="../../assets/indexImg/four_logo.png"
                alt=""
                v-if="isShowFour"
              />
            </transition>
          </div>

          <transition name="four_animation">
            <div class="middle_text" v-if="isShowFour">
              <span>尚行科技</span>
              <span class="middle_textspan">鸿鹄凌云</span>
              <div class="middle_img"></div>
            </div>
          </transition>
        </div>
        <div class="four_bottombox">
          <transition name="four_animation_two">
            <h3 v-if="isShowFour">公司</h3>
          </transition>
          <transition name="four_animation_three">
            <div class="compony_introduce" v-if="isShowFour">
              尚云科技是一家从事大数据云计算、人工智能、产品开发、咨询服务为一体的综合型互联网科技公司。
              公司由一批BAT等一线互联网IT精英人士创建，
              以"快乐工作，认真生活"为愿景， 以"科技与应用创新驱动发展"为使命，
              坚持"客户第一、诚信、激情、拥抱变化"的价值观，
              打造客户有依靠、员工有干劲、公司有前景的团队组织。
            </div>
          </transition>
          <transition name="four_animation_three">
            <img
              src="../../assets/indexImg/four_more.png"
              alt=""
              class="bottom_img"
              @click="goAboutUs"
              v-if="isShowFour"
            />
          </transition>
        </div>
        <div class="swiper-pagination"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { getProductList } from "../../api/index";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  methods: {
    toNavgationNext(index) {
      this.mySwiper.slideTo(index);
    },
    // 下一个轮播图
    toNext() {
      this.mySwiper.slideNext();
    },
    // 左侧分页移入移除
    enterRightNav(index) {
      this.rightNav[index].isShow=true
    },
    leaveRightNav(index) {
      this.rightNav[index].isShow=false
    },

    onSwiper(swiper) {
      this.currentIndex = swiper.realIndex;
    },

    // 第一个轮播图跳转网页
    jumpWebPage(index) {
      if (this.firstCarouselData[index].jumpwebsite) {
        // 点击跳转页面
        window.open(`${this.firstCarouselData[index].jumpwebsite}`, "_blank");
      }
    },
    // 轮播三动画
    slidingSlide(activeIndex) {
      this.currentIndex = activeIndex.realIndex;
      if (activeIndex.realIndex === 2) {
        this.isShow = true;
        this.isShowFour = false;
        setTimeout(() => {
          this.isThreeBottomImg = true;
        }, 1000);
      } else if (activeIndex.realIndex === 3) {
        this.isShow = false;
        this.isThreeBottomImg = false;
        this.isShowFour = true;
      } else {
        this.isShow = false;
        this.isThreeBottomImg = false;
        this.isShowFour = false;
      }
    },
    // 去产品页
    toProductDetail(index) {
      this.$router.push({
        path: "/productDetil",
        query: { id: index },
      });
    },
    // 点击网易大图,去新闻详情页
    goNewsDetail() {
      this.$router.push({
        path: "/newsDetail",
        query: { id: 69 },
      });
    },
    toNewsPage() {
      this.$router.push({
        path: "/news",
      });
    },
    // 去我的页面
    goAboutUs() {
      this.$router.push({
        path: "/aboutUs",
      });
    },
    // 点击更多去产品页
    goProductPage() {
      this.$router.push({ path: "/productPage" });
    }
  },
  data() {
    return {
      firstCarouselData: null,
      //   第三页轮播图显示
      isShow: false,
      //   第三页轮播图底部图片显示
      isThreeBottomImg: false,
      secondCarouselData: null,
      // loading状态
      loading: true,
      isShowFour: false,
      isChange: true,
      mySwiper: null, //swiper实例
      //右侧nav内容
      rightNav: [
        {
          title: "首页 ———",
          isShow:false
        },
        {
          title: "产品 ———",
          isShow:false
        },
        {
          title: "新闻中心 —",
          isShow:false
        },
        {
          title: "关于我们 —",
          isShow:false
        },
      ],
      // 大轮播当前页
      currentIndex: 0,
    };
  },
  created() {
    getProductList().then((res) => {
      console.log(res)
      //  获取第一个轮播数据,res.rows的前三个和最后一个
      this.firstCarouselData=[]
      this.secondCarouselData=[]
      this.loading = false;
      res.rows.forEach((item) => {
        // 后台控制第二个轮播的数据
        if (item.enabled==1) {
          this.secondCarouselData.push(item);
        } if(item.homepagebroadcast){
          // 后台控制首页第一个轮播的数据
          this.firstCarouselData.push(item)
        }
      });
      this.secondCarouselData.reverse();
    });
  },
  mounted() {
    this.mySwiper = document.querySelector("#demo2").swiper;
  },
};
</script>

<style lang="less" scoped>
.box {
  position: fixed;
  height: 100%;
  width: 100%;
  min-width: 1200px;
  .carousel_style {
    width: 100%;
    height: 100%;
    z-index: 1001;
  }
  .left_navgation {
    position: fixed;
    right: 2%;
    top: 35%;
    z-index: 1003;
    li {
      background-color: #fff;
      cursor: pointer;
      transition: background-color 0.5s;
      -moz-transition: background-color 0.5s; /* Firefox 4 */
      -webkit-transition: background-color 0.5s; /* Safari 和 Chrome */
      -o-transition: background-color 0.5s; /* Opera */
      border-radius: 50%;
      margin-bottom: 45px;
      width: 10px;
      height: 10px;
      position: relative;
      box-shadow: 0px 0px 10px #ccc;
      -moz-box-shadow: 0px 0px 10px #ccc;
      -webkit-box-shadow: 0px 0px 10px #ccc;
      span {
        position: absolute;
        left: -95px;
        top: -6px;
        color: #71c1ff;
      }
    }
  }



.rightNav_txt_fade-enter-from,
.rightNav_txt_fade-leave-to {
    opacity: 0;
    transform: translateX(-20px);

}

.rightNav_txt_fade-enter-active,
.rightNav_txt_fade-leave-active {
     transition: all 0.5s linear;

}





  .main_right_nav_active {
    background-color: #71c1ff !important;
  }
  .carousel_box {
    height: 100% !important;
  }
  &:deep(.el-carousel__container) {
    height: 100%;
  }
  .one_pic {
    height: 100%;
  }
  &:deep(.el-carousel__button) {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 10px;
  }
  // 第一轮播图页 左右按钮大小
  &:deep(.el-carousel__arrow i) {
    font-size: 50px;
    font-weight: 1000;
  }
  &:deep(.el-carousel__arrow:hover) {
    background-color: transparent;
  }
}
&:deep(.el-carousel__arrow--right) {
  right: 100px;
}
&:deep(.el-carousel__arrow--left) {
  left: 100px;
}
&:deep(.swiper-pagination-bullet) {
  width: 12px;
  height: 12px;
  background: #fff;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.5s;
}
&:deep(.my-bullet-active) {
  background-color: #71c1ff !important;
}

&:deep(.swiper-container-3d .swiper-slide-shadow-right) {
  background-image: none !important;
}

&:deep(.swiper-container-3d .swiper-slide-shadow-left) {
  background-image: none !important;
}
// 大轮播分页
&:deep(.swiper-container-vertical > .swiper-pagination-bullets) {
  right: 50px;
}
&:deep(.swiper-container-vertical
    > .swiper-pagination-bullets
    .swiper-pagination-bullet) {
  margin: 40px 0;
}
// 大轮播分页hover
.swiper-pagination:nth-child(1):hover {
  background-color: green !important;
}
.swiper-pagination:nth-child(2):hover {
  background-color: hotpink !important;
}

&:deep(.swiper-pagination-bullet:nth-child(4):hover) {
  transition: all 2s linear;
}
&:deep(.swiper-pagination-bullet:nth-child(4):hover::after) {
  content: "首页 ———";
  color: white;
  width: 214px;
  height: 20px;
  right: -15px;
  bottom: 36px;
  position: absolute;
  opacity: 1;
  transform: translateX(20px);
  // animation: identifier 1s linear;
  transition: all 2s linear;
}

@keyframes identifier {
  from {
    transform: translate(200px);
  }
  to {
    transform: translate(0px);
  }
}

// 最底部按钮
.bottom_down {
  img {
    width: 154px;
    height: 50px;
    position: absolute;
    bottom: 0px;
    z-index: 12;
    left: calc(50% - 77px);
    cursor: pointer;
    transition: opacity 0.5s;
    -moz-transition: opacity 0.5s; /* Firefox 4 */
    -webkit-transition: opacity 0.5s; /* Safari 和 Chrome */
    -o-transition: opacity 0.5s; /* Opera */
  }
}
.bottom_down img:last-child {
  opacity: 0;
}

.bottom_down:hover img:first-child {
  opacity: 0;
}

.bottom_down:hover img:last-child {
  opacity: 1;
}

img {
  height: 100%;
  width: 100%;
}

&:deep(.el-carousel__indicators--horizontal) {
  bottom: 40px;
}
.three_swiper {
  position: relative;
}
.three_leftbox {
  position: absolute;
  left: 15%;
  height: 80%;
  width: 45%;
}
.three_rightbox {
  width: 40%;
  position: absolute;
  right: 13%;
  top: 10%;
  min-width: 500px;
  img {
    display: block;
    cursor: pointer;
  }
}
.three_bottombox {
  position: absolute;
  bottom: -6%;
  // width: 50%;
  height: 40%;
  left: 15%;
  z-index: 100;
  min-width: 500px;
  img {
    width: 518px;
    height: 212px;
  }
}
.four_topbox {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  .logo_box {
    width: 100%;
    text-align: center;
    img {
      height: 136px;
      width: 136px;
      margin-top: 130px;
    }
  }
}
.four_bottombox {
  position: fixed;
  width: 100%;
  height: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  h3 {
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }
}
.middle_text {
  color: white;
  font-size: 36px;
  font-family: 微软雅黑;
  position: relative;
}

.middle_textspan {
  margin-left: 40px;
}
.compony_introduce {
  line-height: 26px;
  width: 100%;
  text-align: center;
  font-family: 微软雅黑;
  position: relative;
  margin: 18px 0px 30px;
  width: 800px;
  font-size: 14px;
  margin-top: -100px;
}
// 轮播页2 轮播左右按钮
&:deep(.swiper-button-next, .swiper-container-rtl .swiper-button-prev) {
  right: 80px;
  left: auto;
  font-size: 80px;
  font-weight: bolder;
}
&:deep(.swiper-button-prev, .swiper-container-rtl .swiper-button-next) {
  left: 80px;
  right: auto;
  font-size: 80px;
  font-weight: bolder;
}
// 轮播二底部更多按钮
.bottom_img {
  position: absolute;
  left: calc(50% - 52px);
  width: 104px;
  height: 50px;
  bottom: 24%;
  cursor: pointer;
}
// 第三轮播页下方图片动画
.change-enter-from,
.change-leave-to {
  opacity: 0;
}
.change-enter-active,
.change-leave-active {
  transition: all 1s linear;
}

.change-enter-to,
.change-leave-from {
  opacity: 1;
}
// 第三轮播页上方图片过渡
.top_change-enter-from,
.top_change-leave-to {
  opacity: 0;
  transform: translateX(150px);
}

.top_change-enter-active,
.top_change-leave-active {
  transition: all 1s;
}
.top_change-enter-to,
.top_change-leave-from {
  opacity: 1;
  transform: translateX(0);
}
// 第一个轮播图的img
.first_carousel_img {
  cursor: pointer;
}

.top_img_bgn {
  height: 380px;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0,.4);
  img {
    width: 100%;
    height: 100%;
    opacity: .3;
  }
}
.second_swiper {
  position: relative;
  .second_bottom_img {
    position: absolute;
    right: 14%;
    width: 104px;
    height: 50px;
    z-index: 1001;
    bottom: 17%;
    cursor: pointer;
  }
}
.second_carousel_box {
  width: 100%;
  height: 55%;
  overflow: hidden;
  img {
    width: 90%;
    height: 100%;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.second_carousel {
  margin-top: 10%;
  width: 70%;
  height: 100%;
}
.middle_img {
  width: 20px;
  height: 7px;
  background-color: white;
  position: absolute;
  top: 50px;
  left: 157px;
  background-color: #71cfff;
}
// 轮播图四的动画
// 第四轮播页上方图片动画

.four_top_logo-enter-from,
.four_top_logo-leave-to {
  transform: rotate(-360deg);
  -ms-transform: rotate(-360deg); /* IE 9 */
  -moz-transform: rotate(-360deg); /* Firefox */
  -webkit-transform: rotate(-360deg); /* Safari 和 Chrome */
  -o-transform: rotate(-360deg); /* Opera */
}

.four_top_logo-enter-active,
.four_top_logo-leave-active {
  transition: all 1s;
}
.four_top_logo-enter-to,
.four_top_logo-leave-from {
  transform: rotate(0deg);
}
// 第四轮播页第一个动画
.four_animation-enter-from,
.four_animation-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
.four_animation-enter-active,
.four_animation-leave-active {
  transition: all 1s;
  transition: all 1s 0.3s;
}
.four_animation-enter-to,
.four_animation-leave-from {
  opacity: 1;
  transform: translateY(0px);
}
// 轮播四第二个动画
.four_animation_two-enter-from,
.four_animation_two-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
.four_animation_two-enter-active,
.four_animation_two-leave-active {
  transition: all 1s 0.8s;
}
.four_animation_two-enter-to,
.four_animation_two-leave-from {
  opacity: 1;
  transform: translateY(0px);
}

//第三个动画
.four_animation_three-enter-from,
.four_animation_three-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.four_animation_three-enter-active,
.four_animation_three-leave-active {
  transition: all 1s 1s;
}
.four_animation_three-enter-to,
.four_animation_three-leave-from {
  opacity: 1;
  transform: translateY(0px);
}
</style>
